const RelyConfig = {
    test: {
        vodData: {
            doRegion: "cn-shanghai",
            abRegion: "ap-southeast-1",
            userId: "1657172572736971"
        },
        ossData: {
            region: "oss-cn-zhangjiakou",
            accessKeyId: "LTAI4G8v8h23vgX9HBUKXrX1",
            accessKeySecret: "Y6172NgUodmpX0BKz5UA4P4dkWqwqL",
            bucket: "dev-attached-file"
        },
        agoraData: '7225f39ca807459aae8412cb6a994f59'
    },
    idc: {
        vodData: {
            doRegion: "cn-shanghai",
            abRegion: "ap-southeast-1",
            userId: "1657172572736971"
        },
        ossData: {
            region: "oss-cn-shanghai",
            accessKeyId: "LTAI5tMTgZGU2aVRQRjivr3z",
            accessKeySecret: "l9yyqbV8rQgeMToLzf2PjF53Xj2KHi",
            bucket: "attached-file"
        },
        agoraData: 'b640c804764a43e386803f9ec4ea3029'
    }
}

const handleRelyConfig = (env) => {
    let _env = env == 'idc' ? 'idc' : 'test';
    let isDo = window.navigator.language == "zh-CN" ? true : false;
    let data = Object.assign({}, RelyConfig[_env]);
    data.vodData.region = isDo ? data.vodData.doRegion : data.vodData.abRegion;
    delete data.vodData.doRegion;
    delete data.vodData.abRegion;
    return data;
}
export default handleRelyConfig(process.env.VUE_APP_API_ENV)